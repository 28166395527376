import { CognitoUser } from 'amazon-cognito-identity-js';

import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Snackbar from '../components/snackbar';
import { Container, Link, LoadingButton, Stack, TextField, Typography } from '../mui';
import UserPool from '../UserPool';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

function EmailConfirmation() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [confirmCode, setConfirmCode] = useState('');
  const [loader, setLoader] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState('');

  const userName = state?.userName ? state.userName : null;

  const handleClick = (e) => {
    e.preventDefault();
    setLoader(true);
    const user = new CognitoUser({
      Username: userName,
      Pool: UserPool,
    });
    user.confirmRegistration(confirmCode, true, (err) => {
      if (err) {
        console.log(err);
        setLoader(false);
        setOpenSnackbar(true);
        setError(err.message);
      } else {
        setLoader(false);
        navigate('/login');
      }
    });
  };

  return (
    <>
      <Helmet>
        <title> Confirm Email </title>
      </Helmet>
      <Snackbar openSnackbar={openSnackbar} severity="error" message={error} />
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Stack spacing={2}>
              <Typography sx={{ fontSize: 25, fontWeight: 'bold' }}>Confirm Email</Typography>

              <TextField
                name="code"
                label="Confirmation Code"
                placeholder="Type your Confirmation Code"
                value={confirmCode}
                onChange={(e) => setConfirmCode(e.target.value)}
              />

              <LoadingButton
                fullWidth
                loading={loader}
                size="large"
                type="submit"
                variant="contained"
                disabled={!(userName && confirmCode)}
                onClick={handleClick}
              >
                Confirm
              </LoadingButton>
            </Stack>
            <Typography variant="body2" sx={{ textAlign: 'center', mt: 3 }}>
              Already have an account?
              <Link
                variant="subtitle2"
                sx={{ textDecoration: 'none', cursor: 'pointer' }}
                onClick={() => navigate('/login')}
              >
                Back to Login
              </Link>
            </Typography>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

export default EmailConfirmation;
