import moment from 'moment';
import { get } from 'lodash';
import axios from '../utils/axios';
import { setSnackBarAlert } from '../redux/slices/AlertsSlice';
import { setAgGridLoader, setPaginationPageData } from '../redux/slices/MasterSlice';
import { errorMessageHandler } from '../utils/helpers';

const getAllTools = (signal) => async (dispatch) => {
  try {
    const { data: response } = await axios.get('/api/allTools', {
      ...(signal && { signal }),
    });
    if (response.status && response.data) {
      return {
        customTools: response.data.custom_tools,
        systemTools: response.data.system_tools,
      };
    }
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const handleSystemToolsAction = (id, action) => async (dispatch) => {
  try {
    const isDisabled = action;
    const { data: response } = await axios.post('/api/systemToolsSettings', {
      systemToolId: id,
      isDisabled,
    });
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const handleSystemToolsUpdate = (id, payload) => async (dispatch) => {
  try {
    const { data: response } = await axios.patch(`/api/SystemTools/${id}`, payload);
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const createCustomTool = (payload) => async (dispatch) => {
  try {
    const { data: response } = await axios.post('/api/customTools', payload);
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const updateCustomTool = (id, payload) => async (dispatch) => {
  try {
    const { data: response } = await axios.patch(`/api/customTools/${id}`, payload);
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const getCustomToolById = (id, signal) => async (dispatch) => {
  try {
    const { data: response } = await axios.get(`/api/customTools/${id}`, {
      ...(signal && { signal }),
    });
    if (response.status && response.data) {
      return {
        name: response.data?.name ?? '',
        description: response.data?.description ?? '',
        customPrompt: response.data?.system_prompt ?? '',
        knowledgebase: response.data?.use_knowledge_base,
        model: response.data?.model_name ?? '',
        temperature: response.data?.model_temperature ?? 0,
      };
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const deleteCustomToolById = (id) => async (dispatch) => {
  try {
    const { data: response } = await axios.delete(`/api/customTools/${id}`);
    if (response.status) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return true;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const updateMessageReaction = (id, reaction, signal) => async (dispatch) => {
  try {
    const { data: response } = await axios.patch(`/chat/react/${id}`, { reaction }, { ...(signal && { signal }) });
    if (response.status) {
      return true;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error?.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const generateReports = (filteredValues, page, signal) => async (dispatch) => {
  try {
    dispatch(setAgGridLoader(true));
    const filters = [];

    if (filteredValues?.userData) {
      filters.push({
        column_name: 'user_id',
        value: filteredValues.userData.id,
        type: 'exact',
      });
    }
    if (filteredValues?.feedback) {
      filters.push({
        column_name: 'reaction',
        value: filteredValues.feedback,
        type: 'exact',
      });
    }
    if (filteredValues?.startDate && filteredValues?.endDate && filteredValues?.timeStamp === 'custom') {
      filters.push({
        value: 'custom',
        column_name: 'created_at',
        startDate: filteredValues.startDate,
        endDate: filteredValues.endDate,
        type: 'exact',
      });
    } else if (filteredValues?.timeStamp && filteredValues?.timeStamp !== 'custom') {
      filters.push({
        value: filteredValues.timeStamp,
        column_name: 'created_at',
        type: 'exact',
      });
    }

    const payload = {
      page,
      page_size: 10,
      type: 'FILTER',
      filters,
    };

    const { data: response } = await axios.post('/api/admin/report', payload, { ...(signal && { signal }) });
    if (response.data) {
      const paginationPageData = {
        totalPageCount: response.total_page_count,
        totalCount: response.total_count,
        pageSize: response.page_size,
        pageNo: response.page,
      };
      dispatch(setPaginationPageData(paginationPageData));
      return response.data.map((data) => ({
        id: data.session_id,
        email: data.email,
        prompt: data.prompt,
        response: data.response,
        feedback: data.reaction,
        timestamp: data.created_at ? moment(data.created_at).format('YYYY-MM-DD hh:mm:ss a') : '-',
      }));
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error?.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  } finally {
    dispatch(setAgGridLoader(false));
  }
  return false;
};

const downloadReport = (filteredValues) => async (dispatch) => {
  try {
    const filters = [];

    if (filteredValues?.userData) {
      filters.push({
        column_name: 'user_id',
        value: filteredValues.userData.id,
        type: 'exact',
      });
    }
    if (filteredValues?.feedback) {
      filters.push({
        column_name: 'reaction',
        value: filteredValues.feedback,
        type: 'exact',
      });
    }
    if (filteredValues?.startDate && filteredValues?.endDate && filteredValues?.timeStamp === 'custom') {
      filters.push({
        value: 'custom',
        column_name: 'created_at',
        startDate: filteredValues.startDate,
        endDate: filteredValues.endDate,
        type: 'exact',
      });
    } else if (filteredValues?.timeStamp && filteredValues?.timeStamp !== 'custom') {
      filters.push({
        value: filteredValues.timeStamp,
        column_name: 'created_at',
        type: 'exact',
      });
    }

    const payload = {
      type: 'EXPORT',
      filters,
    };

    const response = await axios.post('/api/admin/report', payload);

    if (get(response, 'headers.content-disposition', false)) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const fileName = `${get(response, 'headers.content-disposition', 'user_list')}`.replace(/\s/g, '').split('=')[1];
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up after the download
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      return true;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error?.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const getUsersDropdown = (signal) => async (dispatch) => {
  try {
    const { data: response } = await axios.get('/users/api/dropDownList', { ...(signal && { signal }) });
    if (response.data) {
      return response.data;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error?.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

export {
  getAllTools,
  handleSystemToolsAction,
  handleSystemToolsUpdate,
  createCustomTool,
  updateCustomTool,
  getCustomToolById,
  deleteCustomToolById,
  updateMessageReaction,
  generateReports,
  downloadReport,
  getUsersDropdown,
};
