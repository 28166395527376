import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// mui
import { Fab, Slide, TextField } from '@mui/material';
import {
  Box,
  Drawer,
  Typography,
  Stack,
  Divider,
  LogoutIcon,
  SettingsIcon,
  Button,
  PersonAddAltRoundedIcon,
  SchoolIcon,
  Grid,
  SearchOffIcon,
  AssessmentIcon,
} from '../../../mui';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import InviteDialog from '../../../pages/InviteDialog';
import SettingsDialogue from '../../../pages/SettingsDialogue';
import LearnDialog from '../../../pages/LearnDialog';
import { getSettingsData } from '../../../redux/actions/SettingsActions';
import { defaultSettingsKeys } from '../../../utils/helpers';
import Iconify from '../../../components/iconify';
// redux
import { setChatSearchTitle } from '../../../redux/slices/MasterSlice';
// context
import { QueriesHistory } from '../../../store/QueriesHistory';

const useStyles = makeStyles((theme) => ({
  simpleBarWrapper: {
    '& .simplebar-content': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .simplebar-wrapper': {
      borderRightStyle: 'none',
    },
  },
}));

const NAV_WIDTH = 280;

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav, handleThemeChange, isDarkMode, textSize, setTextSize }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const queryData = useContext(QueriesHistory);
  const chatSearchTitle = useSelector((state) => state.Master.chatSearchTitle);

  const isDesktop = useResponsive('up', 'lg');
  const logoutHandler = () => {
    localStorage.clear();
    queryData.resetQueryHistory();
    navigate('/validate');
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isDarkMode]);
  useEffect(() => {
    (async () => {
      dispatch(getSettingsData());
    })();
  }, [dispatch]);
  const settings = useSelector((state) => state.settings.settings);
  const getLogo = () => {
    let logo;
    // eslint-disable-next-line array-callback-return
    settings.map((setting) => {
      if (setting.key === defaultSettingsKeys.LOGO) {
        logo = setting.value;
      }
    });
    if (logo) {
      return logo;
    }
    return null;
  };
  const [openDialog, setOpenDialog] = useState({ dialogName: '', status: false });
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const userEmail = localStorage.getItem('userName');
  const isAccessReport = userEmail && userEmail.includes('@accent-technologies.com');

  const classes = useStyles();

  const checkLocationPath = () => {
    if (pathname !== '/home') navigate('/home');
  };

  const handleOpen = () => {
    setOpen(!open);
    if (search) setSearch('');
  };

  const handleClose = () => {
    dispatch(setChatSearchTitle(''));
    setOpen(false);
    if (search) setSearch('');
  };

  const handleSearch = (e) => {
    e.preventDefault();
    checkLocationPath();
    if (search) dispatch(setChatSearchTitle(search));
    // if search value not exist but wanna clear search
    // with empty ''
    if (!search && chatSearchTitle) dispatch(setChatSearchTitle(''));
  };

  const onPromptCreate = (value) => {
    checkLocationPath();
    setOpenDialog({ dialogName: '', status: false });
    if (value) queryData.setPromptValue(value);
  };

  const renderContent = (
    <Scrollbar
      className={classes.simpleBarWrapper}
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {settings ? (
          <Box
            component="img"
            alt="logo"
            src={getLogo() ?? (isDarkMode ? 'assets/logo-white.png' : 'assets/logo.png')}
            sx={
              isDarkMode
                ? {
                    width: 160,
                    maxHeight: '45px',
                    objectFit: 'contain',
                    padding: '5px',
                    borderRadius: 1.5,
                    flexShrink: 0,
                  }
                : { width: 170, maxHeight: '45px', objectFit: 'contain', borderRadius: 1.5, flexShrink: 0 }
            }
          />
        ) : null}
      </Box>

      <Box sx={{ mb: 3, mx: 2.5 }}>
        <Grid container justifyContent={'center'} alignItems={'center'} rowSpacing={2} columnSpacing={0.5}>
          <Grid item xs={10}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                checkLocationPath();
                queryData.newChatHandler();
              }}
              sx={{ boxShadow: 0 }}
            >
              New Chat
            </Button>
          </Grid>

          <Grid item xs={2}>
            {!chatSearchTitle ? (
              <Fab
                color="primary"
                size="small"
                variant="extended"
                sx={{ borderRadius: 1, boxShadow: 'none' }}
                onClick={handleOpen}
              >
                <Iconify icon="eva:search-fill" />
              </Fab>
            ) : (
              <Fab
                color="primary"
                size="small"
                variant="extended"
                sx={{ borderRadius: 1, boxShadow: 'none' }}
                onClick={handleClose}
              >
                <SearchOffIcon sx={{ width: 20, height: 20 }} />
              </Fab>
            )}
          </Grid>
          <Grid item xs={12}>
            <Slide direction="left" in={open} mountOnEnter unmountOnExit>
              <form onSubmit={handleSearch}>
                <TextField
                  size="small"
                  value={search}
                  autoFocus
                  fullWidth
                  placeholder="Search…"
                  onChange={(e) => setSearch(e.target.value.trimStart())}
                />
              </form>
            </Slide>
          </Grid>
        </Grid>
      </Box>

      <NavSection checkLocationPath={checkLocationPath} />

      <Box sx={{ flexGrow: 1 }} />

      <Divider sx={{ borderBottomWidth: 2 }} />

      <Stack direction="column" spacing={2} sx={{ px: 2.5, py: 2, pb: 10, mt: 2 }}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          spacing={3}
          onClick={() => setOpenDialog({ dialogName: 'settings', status: true })}
        >
          <SettingsIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
          <Typography sx={{ color: '#A4A4A4', fontWeight: '500' }}>Settings</Typography>
        </Stack>
        {/* <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ cursor: 'pointer' }} spacing={3}>
          <AdminPanelSettingsIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
          <Typography sx={{ color: '#A4A4A4', fontWeight: '500' }}>Admin</Typography>
        </Stack> */}
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          spacing={3}
          onClick={() => setOpenDialog({ dialogName: 'learn', status: true })}
        >
          <SchoolIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
          <Typography sx={{ color: '#A4A4A4', fontWeight: '500' }}>Learn</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          spacing={3}
          onClick={() => setOpenDialog({ dialogName: 'invite', status: true })}
        >
          <PersonAddAltRoundedIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
          <Typography sx={{ color: '#A4A4A4', fontWeight: '500' }}>Invite</Typography>
        </Stack>
        {isAccessReport && (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            spacing={3}
            onClick={() => navigate('/report')}
          >
            <AssessmentIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
            <Typography sx={{ color: '#A4A4A4', fontWeight: '500' }}>Report</Typography>
          </Stack>
        )}
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          spacing={3}
          onClick={logoutHandler}
        >
          <LogoutIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
          <Typography sx={{ color: '#A4A4A4', fontWeight: '500' }}>Log Out</Typography>
        </Stack>
      </Stack>
      {openDialog.dialogName === 'learn' ? (
        <LearnDialog
          openDialog={openDialog.status}
          setOpenDialog={() => setOpenDialog({ dialogName: '', status: false })}
          onPromptCreate={onPromptCreate}
        />
      ) : openDialog.dialogName === 'invite' ? (
        <InviteDialog
          openDialog={openDialog.status}
          setOpenDialog={() => setOpenDialog({ dialogName: '', status: false })}
        />
      ) : openDialog.dialogName === 'settings' ? (
        <SettingsDialogue
          openDialog={openDialog.status}
          setOpenDialog={() => setOpenDialog({ dialogName: '', status: false })}
          handleThemeChange={handleThemeChange}
          textSize={textSize}
          setTextSize={setTextSize}
        />
      ) : null}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
