import UserPool from './UserPool';

export const appConfig = {
  API_URL: process.env.REACT_APP_API_URL,
};

export const cognitoConfig = {
  LOGIN_URL: `${
    process.env.REACT_APP_COGNITO_API_URL
  }/login?%20&response_type=code&%20&client_id=${UserPool.getClientId()}&%20&redirect_uri=${
    process.env.REACT_APP_COGNITO_REDIRECT_URL
  }&%20state=STATE&%20scope=openid+profile+aws.cognito.signin.user.admin`,
  SIGNUP_URL: `${
    process.env.REACT_APP_COGNITO_API_URL
  }/signup?%20&response_type=code&%20&client_id=${UserPool.getClientId()}&%20&redirect_uri=${
    process.env.REACT_APP_COGNITO_REDIRECT_URL
  }&%20state=STATE&%20scope=openid+profile+aws.cognito.signin.user.admin`,
  LOGOUT_URL: `${
    process.env.REACT_APP_COGNITO_API_URL
  }/logout?%20&response_type=code&%20&client_id=${UserPool.getClientId()}&%20&redirect_uri=${
    process.env.REACT_APP_COGNITO_REDIRECT_URL
  }&%20state=STATE&%20scope=openid+profile+aws.cognito.signin.user.admin`,
};
