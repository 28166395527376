import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  Typography,
  Switch,
  Stack,
  EditRoundedIcon,
  TextField,
  IconButton,
  CheckIcon,
  CircularProgress,
  Box,
  CloseIcon,
} from '../../mui';

const SystemTools = ({
  Tools,
  btnLoader,
  editFieldId,
  editedTitle,
  editedDesc,
  editDescId,
  setEditedTitle,
  setEditFieldId,
  setEditDescId,
  setEditedDesc,
  systemField,
  setSystemField,
  handleToggle,
  handleNameChange,
  handleDescChange,
}) =>
  Tools.map((item) => (
    <Box key={item.id}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          p: '10px',
          height: 60,
          bgcolor: '#f0f0f0',
          border: 'solid 1px #f0f0f0',
          borderRadius: 1,
          cursor: 'pointer',
          m: 1,
        }}
      >
        {editFieldId === item.id ? (
          <TextField
            fullWidth
            variant="standard"
            value={editFieldId === item.id ? editedTitle : item?.name}
            sx={{
              paddingLeft: 3.5,
              border: 'none',
              input: { cursor: 'pointer' },
              '& .MuiInputBase-input': {
                textOverflow: 'ellipsis',
              },
            }}
            InputProps={{
              disableUnderline: true,
            }}
            inputRef={(input) => input && input.focus()}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
        ) : (
          <>
            <IconButton
              size="small"
              onClick={() => item.has_description && setSystemField((value) => (value === item.id ? null : item.id))}
              sx={{ opacity: item.has_description ? 0.8 : 0, width: 20, height: 20 }}
            >
              {systemField === item.id ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
            </IconButton>
            <Typography
              variant="body1"
              sx={{
                color: '#7e7e7e',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              onClick={() => item.has_description && setSystemField((value) => (value === item.id ? null : item.id))}
            >
              {item?.name}
            </Typography>
          </>
        )}
        {!item.is_editable ? (
          <Switch checked={!item.is_disabled} onChange={() => handleToggle(item.id, item.is_disabled)} />
        ) : editFieldId === item.id ? (
          <>
            {btnLoader ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size="18px" />
              </Box>
            ) : (
              <>
                <IconButton onClick={() => setEditFieldId(null)}>
                  <CloseIcon />
                </IconButton>
                <IconButton onClick={() => handleNameChange(item.id)}>
                  <CheckIcon />
                </IconButton>
              </>
            )}
          </>
        ) : (
          <IconButton
            onClick={() => {
              setEditFieldId(item.id);
              setEditedTitle(item?.name);
            }}
          >
            <EditRoundedIcon />
          </IconButton>
        )}
      </Stack>
      {systemField === item.id && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            p: '15px',
            cursor: 'pointer',
            m: 1,
          }}
        >
          {editDescId === item.id ? (
            <TextField
              fullWidth
              variant="standard"
              value={editDescId === item.id ? editedDesc : item?.name}
              sx={{
                border: 'none',
                input: { cursor: 'pointer' },
                '& .MuiInputBase-input': {
                  textOverflow: 'ellipsis',
                },
              }}
              InputProps={{
                disableUnderline: true,
              }}
              inputRef={(input) => input && input.focus()}
              onChange={(e) => setEditedDesc(e.target.value)}
            />
          ) : (
            <Typography
              variant="body2"
              sx={{
                color: '#7e7e7e',
                width: '100%',
              }}
              onClick={() => setSystemField(null)}
            >
              {item.description}
            </Typography>
          )}
          {editDescId === item.id ? (
            <>
              {btnLoader ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress size="18px" />
                </Box>
              ) : (
                <>
                  <IconButton onClick={() => setEditDescId(null)}>
                    <CloseIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDescChange(item.id)}>
                    <CheckIcon />
                  </IconButton>
                </>
              )}
            </>
          ) : (
            <IconButton
              onClick={() => {
                setEditDescId(item.id);
                setEditedDesc(item.description ?? '');
              }}
            >
              <EditRoundedIcon />
            </IconButton>
          )}
        </Stack>
      )}
    </Box>
  ));

export default SystemTools;
