import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Zoom } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { EngineeringIcon, Chip, Divider, Stack, Typography, Button, MenuItem, KeyboardArrowDownIcon } from '../../mui';
import StyledMenu from './styles';
import { getAllTools } from '../../pages/apiCalls';
import { setCustomTools, setSelectedPrompt, setSystemTools } from '../../redux/slices/MasterSlice';
import { QueriesHistory } from '../../store/QueriesHistory';
import HtmlTooltip from '../tooltip';

const DropButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const systemTools = useSelector((state) => state.Master.systemTools);
  const customTools = useSelector((state) => state.Master.customTools);
  const selectedPrompt = useSelector((state) => state.Master.selectedPrompt);
  const queryData = useContext(QueriesHistory);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handelSelect = (value) => {
    if (value && value?.status !== 'COMING_SOON') {
      // for open new chat if type is SEARCH ( part 1 )
      if (value?.search_type === 'SEARCH') queryData.newChatHandler();
      handleClose();
      dispatch(setSelectedPrompt(value?.name));
      localStorage.setItem('search', JSON.stringify(value));
    }
  };

  useEffect(() => {
    const search = JSON.parse(localStorage.getItem('search'));
    if (isEmpty(search) && systemTools?.length && !selectedPrompt) {
      const search = systemTools[0];
      localStorage.setItem('search', JSON.stringify(search));
      dispatch(setSelectedPrompt(search?.name));
    }
  }, [dispatch, systemTools, selectedPrompt]);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const response = await dispatch(getAllTools(controller.signal));
      if (response) {
        dispatch(setSystemTools(response.systemTools));
        dispatch(setCustomTools(response.customTools));
      }
    })();
    return () => controller.abort();
  }, [dispatch]);

  const iconStyle = {
    height: '18px',
    paddingRight: '9px',
  };

  return pathname === '/home' ? (
    <>
      <Button
        sx={{ float: 'right', minWidth: 150, mt: 2, boxShadow: 0 }}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {selectedPrompt}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ height: '100%' }}
      >
        {systemTools?.map(
          (item) =>
            !item.is_disabled && (
              <MenuItem key={item.id} disableRipple={item.status === 'COMING_SOON'} onClick={() => handelSelect(item)}>
                <HtmlTooltip
                  TransitionComponent={Zoom}
                  title={
                    item?.description && (
                      <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                        {item.description}
                      </Typography>
                    )
                  }
                  enterDelay={500}
                  placement="left"
                >
                  <Stack sx={{ width: '100%', alignItems: 'center' }} direction="row">
                    <span style={iconStyle}>
                      <img src={item.icon} alt={item.name ?? 'accent'} style={{ height: '100%' }} />
                    </span>
                    <Typography ml={1.5}>{item.name}</Typography>
                    {item.status !== 'PUBLISHED' && (
                      <Chip
                        label={item.status === 'BETA' ? 'Beta' : 'Soon'}
                        size="small"
                        color="primary"
                        sx={{ mx: 1, bgcolor: item.status === 'BETA' && 'rgb(254,146,30)' }}
                      />
                    )}
                  </Stack>
                </HtmlTooltip>
              </MenuItem>
            )
        )}
        {customTools?.length > 0 && <Divider />}
        {customTools?.map((item) => (
          <MenuItem key={`custom_${item.id}`} onClick={() => handelSelect(item)}>
            <HtmlTooltip
              TransitionComponent={Zoom}
              title={
                item?.description && (
                  <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                    {item.description}
                  </Typography>
                )
              }
              enterDelay={500}
              placement="left"
            >
              <Stack sx={{ width: '100%', alignItems: 'center' }} direction="row">
                <span style={iconStyle}>
                  <EngineeringIcon style={{ height: '100%' }} />
                </span>
                <Typography>{item.name}</Typography>
              </Stack>
            </HtmlTooltip>
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  ) : null;
};

export default DropButton;
