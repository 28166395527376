import { useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  ChatBubbleOutlineIcon,
  Stack,
  Typography,
  EditRoundedIcon,
  DeleteIcon,
  CircularProgress,
  LoadingButton,
  TextField,
  CheckIcon,
  CancelIcon,
} from '../../mui';
import axios from '../../utils/axios';
import Snackbar from '../snackbar';
import Scrollbar from '../scrollbar';
import { QueriesHistory } from '../../store/QueriesHistory';
import { setSelectedPrompt } from '../../redux/slices/MasterSlice';

export default function NavSection({ checkLocationPath }) {
  const [hoverFieldId, setHoverFieldId] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');

  const [chatData, setChatData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [editFieldId, setEditFieldId] = useState(null);
  const [deleteFieldId, setDeleteFieldId] = useState(null);
  const [snackAlert, setSnackAlert] = useState(null);
  const [filterExist, setFilterExist] = useState(false);
  const [page, setPage] = useState(1);
  const queryData = useContext(QueriesHistory);
  const chatID = localStorage.getItem('chatId');

  const systemTools = useSelector((state) => state.Master.systemTools);
  const chatSearchTitle = useSelector((state) => state.Master.chatSearchTitle);

  const dispatch = useDispatch();

  const updateChatTitle = async (id) => {
    setUpdateLoader(true);
    setSnackAlert(null);
    try {
      const payload = { message: editedTitle };
      const { data: response } = await axios.put(`/chat/${id}`, payload);
      if (response.status) {
        setSnackAlert({ severity: 'success', message: response.message });
        await chatHistoryApiHandler(chatSearchTitle);
      } else {
        setSnackAlert({ severity: 'error', message: response.message });
      }
    } catch (err) {
      console.log('err', err);
      setSnackAlert({ severity: 'error', message: 'Something went wrong !' });
    } finally {
      setUpdateLoader(false);
      setEditFieldId(null);
      setDeleteFieldId(null);
    }
  };

  const deleteChatTitle = async (id) => {
    setDeleteLoader(true);
    setSnackAlert(null);
    try {
      const payload = { message: editedTitle };
      const { data: response } = await axios.delete(`/chat/${id}`, payload);
      if (response.status) {
        setSnackAlert({ severity: 'success', message: response.message });
        chatHistoryApiHandler(chatSearchTitle);
        if (id === Number(chatID) || id === queryData.firstChatId) queryData.resetQueryHistory();
      } else {
        setSnackAlert({ severity: 'error', message: response.message });
      }
    } catch (err) {
      console.log('err', err);
      setSnackAlert({ severity: 'error', message: 'Something went wrong !' });
    } finally {
      setEditFieldId(null);
      setDeleteFieldId(null);
      setDeleteLoader(false);
    }
  };

  const chatHistoryApiHandler = useCallback(
    async (filterValue) => {
      try {
        const payload = {
          page: 1,
          page_size: 20,
          filters: [],
        };

        if (filterValue) {
          payload.filters.push({
            column_name: 'message',
            type: 'like',
            value: filterValue,
          });
          setLoader(true);
          setFilterExist(true);
        } else if (filterExist) {
          // loader for empty search
          setLoader(true);
          setFilterExist(false);
        }
        const { data: response } = await axios.post('/chat', payload);
        if (response.status) {
          setPageData({
            totalPageCount: response?.total_page_count,
            totalCount: response?.total_count,
          });
          setChatData(response.data);
        } else {
          setSnackAlert({ severity: 'error', message: response.message });
        }
      } catch (err) {
        console.log('err', err);
        setSnackAlert({ severity: 'error', message: 'Something went wrong !' });
      } finally {
        setLoader(false);
        setBtnLoader(false);
      }
    },
    [filterExist]
  );

  const showMoreHandler = async (page, filterValue) => {
    setPage(page);
    setBtnLoader(true);
    try {
      const payload = {
        page,
        page_size: 20,
        filters: [],
      };
      if (filterValue)
        payload.filters.push({
          column_name: 'message',
          type: 'like',
          value: filterValue,
        });

      const { data: response } = await axios.post('/chat', payload);
      if (response.status) {
        setPageData({
          totalPageCount: response?.total_page_count,
          totalCount: response?.total_count,
        });
        setChatData((prev) => [...prev, ...response.data]);
      } else {
        setSnackAlert({ severity: 'error', message: response.message });
      }
    } catch (err) {
      console.log('err', err);
      setSnackAlert({ severity: 'error', message: 'Something went wrong !' });
    } finally {
      setBtnLoader(false);
    }
  };

  useEffect(() => {
    chatHistoryApiHandler(chatSearchTitle);
  }, [chatHistoryApiHandler, queryData.chatTitle, chatSearchTitle]);

  return (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box>
        {snackAlert && <Snackbar severity={snackAlert.severity} message={snackAlert.message} />}

        {loader ? (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '50vh' }}>
            <CircularProgress size="20px" />
          </Box>
        ) : chatData.length ? (
          <>
            {chatData.map((chat, index) => (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                key={index}
                spacing={2}
                onMouseEnter={() => setHoverFieldId(chat.id)}
                onMouseLeave={() => setHoverFieldId(null)}
                sx={{
                  p: '5px 10px',
                  bgcolor:
                    hoverFieldId === chat.id || queryData.contextChatId === chat.id || queryData.firstChatId === chat.id
                      ? 'rgba(124, 124, 124, 0.41)'
                      : '',
                  borderRadius: 1,
                  m: 1,
                }}
              >
                <ChatBubbleOutlineIcon
                  sx={{
                    color:
                      hoverFieldId === chat.id ||
                      queryData.contextChatId === chat.id ||
                      queryData.firstChatId === chat.id
                        ? 'white'
                        : '#a4a4a4',
                    fontSize: 18,
                  }}
                />
                {editFieldId !== chat.id ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      width: '100%',
                      height: '32px',
                      cursor: 'pointer',
                      overflow: 'hidden',
                    }}
                    onClick={() => {
                      checkLocationPath();
                      const search = JSON.parse(localStorage.getItem('search'));
                      // for open new chat if type is SEARCH ( part 2 )
                      if (search?.search_type === 'SEARCH' && systemTools?.length) {
                        const search = systemTools[0];
                        localStorage.setItem('search', JSON.stringify(search));
                        dispatch(setSelectedPrompt(search?.name));
                      }
                      queryData.setChatIdHandler(chat.id);
                    }}
                  >
                    <Typography
                      color="gray"
                      sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                      {chat.message}
                    </Typography>
                  </Box>
                ) : (
                  <TextField
                    // disabled={editFieldId !== chat.id}
                    variant="standard"
                    value={editFieldId === chat.id ? editedTitle : chat.message}
                    sx={{
                      border: 'none',
                      input: { cursor: 'pointer' },
                      '& .MuiInputBase-input': {
                        textOverflow: 'ellipsis',
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputRef={(input) => input && input.focus()}
                    onChange={(e) => setEditedTitle(e.target.value)}
                  />
                )}

                {hoverFieldId === chat.id ? (
                  <>
                    {editFieldId === chat.id || deleteFieldId === chat.id ? (
                      <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        {updateLoader || deleteLoader ? (
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress size="15px" />
                          </Box>
                        ) : (
                          <CheckIcon
                            sx={{
                              color: hoverFieldId === chat.id ? 'white' : '#a4a4a4',
                              fontSize: 18,
                              cursor: 'pointer',
                            }}
                            onClick={() => (editFieldId ? updateChatTitle(chat.id) : deleteChatTitle(chat.id))}
                          />
                        )}
                        <CancelIcon
                          sx={{
                            color: hoverFieldId === chat.id ? 'white' : '#a4a4a4',
                            fontSize: 18,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setEditFieldId(null);
                            setDeleteFieldId(null);
                          }}
                        />
                      </Stack>
                    ) : (
                      <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <EditRoundedIcon
                          sx={{
                            color: hoverFieldId === chat.id ? 'white' : '#a4a4a4',
                            fontSize: 18,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setEditedTitle(chat.message);
                            setEditFieldId(chat.id);
                          }}
                        />
                        <DeleteIcon
                          sx={{
                            color: hoverFieldId === chat.id ? 'white' : '#a4a4a4',
                            fontSize: 18,
                            cursor: 'pointer',
                          }}
                          onClick={() => setDeleteFieldId(chat.id)}
                        />
                      </Stack>
                    )}
                  </>
                ) : null}
              </Stack>
            ))}
            {pageData.totalCount !== chatData.length && (
              <div style={{ textAlign: 'center', marginBottom: 5 }}>
                <LoadingButton
                  loading={btnLoader}
                  size="small"
                  onClick={() => showMoreHandler(page + 1, chatSearchTitle)}
                >
                  show more
                </LoadingButton>
              </div>
            )}
          </>
        ) : (
          <Typography sx={{ textAlign: 'center' }}>No data found!</Typography>
        )}
      </Box>
    </Scrollbar>
  );
}
