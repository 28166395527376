import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  systemTools: [],
  customTools: [],
  activeRerender: false,
  selectedPrompt: JSON.parse(localStorage.getItem('search'))?.name ?? '',
  chatSearchTitle: '',
  agGridLoader: true,
  paginationPageData: {},
};

export const MasterSlice = createSlice({
  name: 'MasterSlice',
  initialState,
  reducers: {
    setSystemTools: (state, action) => {
      state.systemTools = action.payload;
    },
    setCustomTools: (state, action) => {
      state.customTools = action.payload;
    },
    setActiveRerender: (state) => {
      state.activeRerender = !state.activeRerender;
    },
    setSelectedPrompt: (state, action) => {
      state.selectedPrompt = action.payload;
    },
    setChatSearchTitle: (state, action) => {
      state.chatSearchTitle = action.payload;
    },
    setAgGridLoader: (state, action) => {
      state.agGridLoader = action.payload;
    },
    setPaginationPageData: (state, action) => {
      state.paginationPageData = action.payload;
    },
  },
});

export const {
  setActiveRerender,
  setSystemTools,
  setCustomTools,
  setSelectedPrompt,
  setChatSearchTitle,
  setAgGridLoader,
  setPaginationPageData,
} = MasterSlice.actions;

export default MasterSlice.reducer;
