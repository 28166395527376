import React from 'react';
import { Typography, Stack, EditRoundedIcon, CircularProgress, Box, DeleteIcon } from '../../mui';

const CustomTools = ({
  Tools,
  hoverFieldId,
  dltBtnLoader,
  editPersonality,
  deletePersonality,
  selectedField,
  setSelectedField,
  setHoverFieldId,
}) =>
  Tools.map((item) => (
    <Box key={item.id}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        onClick={() => setSelectedField((value) => (value === item.id ? null : item.id))}
        onMouseEnter={() => setHoverFieldId(item.id)}
        onMouseLeave={() => setHoverFieldId(null)}
        sx={{
          p: '15px',
          bgcolor: hoverFieldId === item.id ? '#7e7e7e' : '#f0f0f0',
          border: 'solid 1px #f0f0f0',
          borderRadius: 1,
          cursor: 'pointer',
          m: 1,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: hoverFieldId === item.id ? 'white' : '#7e7e7e',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {item.name}
        </Typography>
        {hoverFieldId === item.id && (
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
            <EditRoundedIcon
              sx={{
                color: hoverFieldId === item.id ? 'white' : '#a4a4a4',
                fontSize: 18,
                cursor: 'pointer',
              }}
              onClick={(e) => editPersonality(e, item.id)}
            />
            {dltBtnLoader ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size="18px" />
              </Box>
            ) : (
              <DeleteIcon
                sx={{
                  color: hoverFieldId === item.id ? 'white' : '#a4a4a4',
                  fontSize: 18,
                  cursor: 'pointer',
                }}
                onClick={(e) => deletePersonality(e, item.id)}
              />
            )}
          </Stack>
        )}
      </Stack>
      {selectedField === item.id && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          onClick={() => setSelectedField(null)}
          sx={{
            p: '15px',
            cursor: 'pointer',
            m: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: '#7e7e7e',
              width: '100%',
            }}
          >
            {item.description}
          </Typography>
        </Stack>
      )}
    </Box>
  ));

export default CustomTools;
