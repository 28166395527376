import { useEffect, useRef, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Pagination from '@mui/material/Pagination';
import { Box, Fade, IconButton, InputAdornment, Popper, Stack, TextField } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { promptsJSON } from '../utils/examplePrompts';

export default function ExamplesPromptForm({ category }) {
  const textFieldRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  // Constants for pagination and filtering
  const promptsPerPage = 10;
  const startIndex = (currentPage - 1) * promptsPerPage;
  const endIndex = startIndex + promptsPerPage;

  // Filtering the prompts based on category and search query
  const filteredPromptsByCategory = promptsJSON.filter((item) =>
    item.category.toLowerCase().includes(category ? category.toLowerCase() : '')
  );
  const filteredPromptsBySearch = filteredPromptsByCategory.filter((item) =>
    `${item.title} ${item.description}`.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // Selecting the prompts to display on the current page
  const finalPaginatedPrompts = filteredPromptsBySearch.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCopyPrompt = (event, textFieldValue) => {
    navigator.clipboard.writeText(textFieldValue);
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [category]);

  return (
    <Box m={{ xs: 1, sm: 5 }} sx={{ overflow: 'hidden' }}>
      <TextField
        label="Search"
        placeholder="Search prompts..."
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value.trimStart());
          setCurrentPage(1);
        }}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <Box>
        {finalPaginatedPrompts.map((item, index) => (
          <Accordion
            key={index}
            sx={{
              borderBottom: '1px solid #e0e0e0',
              '&.Mui-expanded': {
                borderBottom: 'none',
              },
              '&:last-child': {
                borderBottom: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                backgroundColor: '#f1f1f1',
                borderRadius: index === 0 && '10px 10px 0 0',
              }}
            >
              <Stack direction="column" spacing={1}>
                <Typography variant="h5">{item.title}</Typography>
                <Typography variant="body2">{item.description}</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: '#f1f1f1',
                borderRadius: '0 0 10px 10px',
              }}
            >
              <Stack direction="column" spacing={1}>
                <Typography variant="h6">Prompt</Typography>
                <TextField
                  fullWidth
                  inputRef={textFieldRef}
                  value={item.prompt}
                  multiline
                  maxRows={7}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end" style={{ position: 'absolute', bottom: '14px', right: '-5px' }}>
                        <IconButton onClick={(event) => handleCopyPrompt(event, item.prompt)} aria-label="Copy Prompt">
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { padding: '15px 25px' },
                  }}
                />
                {item.ideas && <Typography variant="h6">Ideas</Typography>}
                <Typography>{item.ideas}</Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      {finalPaginatedPrompts.length ? (
        <Box mt={2} display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(filteredPromptsBySearch.length / promptsPerPage)}
            shape="rounded"
            color="primary"
            page={currentPage}
            onChange={(event, page) => handlePageChange(page)}
          />
        </Box>
      ) : null}
      <Popper open={open} anchorEl={anchorEl} placement="top" transition sx={{ zIndex: 1300 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box sx={{ p: 1, bgcolor: 'rgba(124, 124, 124, 1)', color: 'white', borderRadius: '10px' }}>Copied</Box>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}
